


<div class="login-body">
  <main class="login-page d-flex align-items-center justify-content-center">
    <div class="login-wrap shadow_body mx-auto">
        <div class="login-header d-flex align-items-center justify-content-center">
            <img src="assets/images/logo.png" alt="logo" class="img-fluid mx-auto">                    
        </div>
        
        <div class="login-field">
            <h4 class="h-20 font-semi text-center mb-3">Admin Login</h4>
            <form name="form" [formGroup]="frm" class="f-field needs-validation" [ngClass]="{'was-validated': frm.touched && !frm.valid}"
            novalidate autocomplete="off">
                <div class="form-group">
                    <input appInputFocus placeholder="Email" formControlName="username" (change)="username.setValue($event.target.value.trim())"
                    type="text" class="form-control" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" name='username'
                    required />

                    <div class="text-danger error" *ngIf="username.touched && !username.valid && username.errors?.required">
                      Please enter email.</div>
                    <div class="text-danger error" *ngIf="username.dirty && username.errors?.pattern"> Email should be in valid
                      format.</div>
                </div>
                <div class="form-group">
                    <input appInputFocus (change)="password.setValue($event.target.value.trim())" formControlName="password"
                    type="password" placeholder="Password" class="form-control" name="password" required=""/>
                    <div class="text-danger error" *ngIf="password.touched && !username.valid && password.errors?.required">
                      Please enter password.
                    </div>
                    
                </div>
                <div class="form-group">                            
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" formControlName="rememberMe" class="custom-control-input" id="remeberme"> 
                        <label class="custom-control-label" for="remeberme"> 
                            <span>Remember me</span>
                        </label>
                    </div>                                                                   
                </div>
                <div class="form-group text-center">
                    <button type="submit" [disabled]=loading (click)="login()" class="btn btn-danger text-uppercase min-w130 ripple-effect-dark">Login<i class="fa fa-spin fa-spinner" *ngIf='loading'></i></button>
                </div>
            </form>
        </div>
        <div class="login-footer">
            <a  [routerLink]="['/forgot-password']"  class="ripple-effect text-uppercase">FORGOT PASSWORD <i class="fa fa-long-arrow-right"></i></a>
        </div>
    </div>
</main>
</div>