import { Injectable, isDevMode } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { TokenManager } from '../app/shared/services/token-manager.service';
import { ToasterService } from '../app/shared/services/toaster.service';
import { TimeoutError } from 'rxjs/util/TimeoutError';
import { notification_msg } from '../app/shared/constants/consts';
import { throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenManager: TokenManager,
    private toaster: ToasterService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any>;
    let head: any = {
      "access-token": this.tokenManager.fetchToken()
    };
    if (!isDevMode()) {
      head = Object.assign(
        {},
        {
          "access-token": this.tokenManager.fetchToken()
        }
      );
    }
    newReq = request.clone({
      setHeaders: head
    });

    return next.handle(newReq).catch(err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.toaster.Error('Please login again', 'Session Expired');
        this.tokenManager.deleteToken();
        this.router.navigate(['/login']);
        // return Observable.empty();
        return null;
      }
      return throwError(err);
      
    });
  }
}
