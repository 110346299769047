<div class="login-body">
  <main class="login-page d-flex align-items-center justify-content-center">
    <div class="login-wrap shadow_body mx-auto">
        <div class="login-header d-flex align-items-center justify-content-center">
            <img src="assets/images/logo.png" alt="logo" class="img-fluid mx-auto">               
        </div>
        <div class="login-field">
                <h4 class="h-20 font-semi text-center mb-3">Reset Password</h4>
            <form action="" [formGroup]="resetForm" class="f-field needs-validation" novalidate [ngClass]="{'was-validated': resetForm.touched && !resetForm.valid}">
                <div class="form-group">
                    <input formControlName="password" (change)="password.setValue($event.target.value.trim())"
                    type="password" placeholder="New Password" class="form-control"  required="" />
                    <div *ngIf="password.touched && !password.valid && password.errors?.required" class="text-danger error">{{errorMsg.NEW_PASSWORD_REQUIRED}}</div>
                    <div *ngIf="password.dirty && password.errors?.minlength" class="text-danger error">{{errorMsg.PASSWORD_MIN_LENGTH(5)}}</div>
                    <div *ngIf="password.dirty && password.errors?.maxlength" class="text-danger error">{{errorMsg.PASSWORD_MAX_LENGTH(12)}}</div>
                </div>
                <div class="form-group">
                  <input (change)="confirm_password.setValue($event.target.value.trim())" formControlName="confirm_password"
                  type="password" placeholder="Confirm Password" class="form-control" required="" />
                  <div *ngIf="confirm_password.touched && !confirm_password.valid && confirm_password.errors?.required" class="text-danger error">{{errorMsg.CONFIRM_PASSWORD_REQUIRED}}</div>
                  <div *ngIf="confirm_password.dirty && confirm_password.errors?.minlength" class="text-danger error">{{errorMsg.PASSWORD_MIN_LENGTH(5)}}</div>
                  <div *ngIf="confirm_password.dirty && confirm_password.errors?.maxlength" class="text-danger error">{{errorMsg.PASSWORD_MAX_LENGTH(12)}}</div>
                  <div class="text-danger error" *ngIf="resetForm.hasError('passError')">
                    {{resetForm.getError('passError').msg}}
                  </div>
              </div>
                <div class="form-group text-center">
                    <button type="submit" [disabled]=loading (click)="resetPassword()" class="btn btn-danger text-uppercase min-w130 ripple-effect-dark">Submit<i class="fa fa-spin fa-spinner" *ngIf='loading'></i></button>
                </div>
            </form>
        </div>
        <div class="login-footer">
            <a routerLink="/login" class="ripple-effect text-uppercase"> <i class="fa fa-long-arrow-left"></i> &nbsp; LOGIN</a>
        </div>
    </div>
</main>
</div>