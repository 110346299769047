import { Component, Output, EventEmitter, ChangeDetectorRef, DoCheck } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { OnRouteService } from "./shared/services/onroute.services";
declare var $: any;
@Component({
  selector: "app-root",
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements DoCheck {
  title = "app";
  constructor(private router: Router, public onRouteService: OnRouteService,  private ref: ChangeDetectorRef) {
    // hold onto the drop down menu
    let dropdownMenu;
    // and when you show it, move it to the body
    $(window).on("show.bs.dropdown", function(e) {
      // grab the menu
      dropdownMenu = $(e.target).find(".dropdown-menu.list-menu");

      // detach it and append it to the body
      $("body").append(dropdownMenu.detach());

      // grab the new offset position
      const eOffset = $(e.target).offset();

      // make sure to place it where it would normally go (this could be improved)
      dropdownMenu.css({
        display: "block",
        top: eOffset.top + $(e.target).outerHeight(),
        left: eOffset.left
      });
    });

    // and when you hide it, reattach the drop down, and hide it normally
    $(window).on("hide.bs.dropdown", function(e) {
      $(e.target).append(dropdownMenu.detach());
      dropdownMenu.hide();
    });

    // router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     $(".dropdown-menu.list-menu").hide();
    //     setTimeout(function() {
    //       $(".selectpicker").selectpicker("refresh");
    //     }, 50);
    //   }
    // });
  }
  public ngDoCheck(): void {
    this.ref.detectChanges();
  }
}
