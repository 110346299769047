import { Injectable } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from "@angular/router";
import "rxjs/add/operator/filter";
import { timeInterval } from "rxjs/operator/timeInterval";
import { AuthService } from "@app/auth/services/auth-service.service";

declare var $: any;

@Injectable()
export class OnRouteService {
  loading = false;

  constructor(private router: Router, private authService: AuthService) {
    this.router.events
      .filter(e => e instanceof NavigationStart)
      .subscribe({
        next: (event: any) => {
          this.loading = true;

          if (event.url === "/login" && this.authService.isLoggedIn()) {
            this.router.navigate(["/dashboard"]);
          }

          this.startCountdown(5);
        }
      });

    this.router.events
      .filter(e => e instanceof NavigationEnd)
      .subscribe({
        next: (event: any) => {
          this.loading = false;

          $(".dropdown-menu.list-menu").hide();
          setTimeout(function() {
            $(".selectpicker").selectpicker("refresh");
          }, 50);

          // setInterval(() => {
          //   $(".table-responsive").mCustomScrollbar({
          //     axis: "x",
          //     theme: "light"
          //   });
          // }, 1000);
        }
      });
  }
  // timer added due to guard
  // if user don't have permission to access that page
  // then NavigationStart events starts but no NavigationEnd happens

  startCountdown(seconds) {
    let counter = seconds;
    const interval1 = setInterval(() => {
      // console.log(counter);
      counter--;

      if (counter < 0) {
        // The code here will run when
        // the timer has reached zero.
        this.loading = false;
        clearInterval(interval1);
        // console.log("Ding!");
      }
    }, 10000);
  }
}
