<div class="login-body">
    <main class="login-page d-flex align-items-center justify-content-center">
      <div class="login-wrap shadow_body mx-auto">
          <div class="login-header d-flex align-items-center justify-content-center">
              <img src="assets/images/logo.png" alt="logo" class="img-fluid mx-auto">               
          </div>
          <div class="login-field">
                  <h4 class="h-20 font-semi text-center mb-3">Forgot Password</h4>
              <form action="" [formGroup]="forgetPasswordResetForm" class="f-field needs-validation" novalidate [ngClass]="{'was-validated': forgetPasswordResetForm.touched && !forgetPasswordResetForm.valid}">
                  <div class="form-group">
                      <input type="email" placeholder="Email" class="form-control" appInputFocus  name="email" formControlName="email" required (change)="email.setValue($event.target.value.trim())"/>
                      <div *ngIf="email.touched && !email.valid && email.errors?.required" class="text-danger error">{{errorMsg.EMAIL_REQUIRED}}</div>
                      <div *ngIf="email.dirty && email.errors?.maxlength" class="invalid-feedback">{{errorMsg.EMAIL_MAX_LENGTH(100)}}</div>
                      <div *ngIf="email.dirty && email.errors?.pattern" class="invalid-feedback">{{errorMsg.EMAIL_INCORRECT_FORMAT}}</div>
                  </div>
                  <div class="form-group text-center">
                      <button type="submit" [disabled]=loading (click)="onSubmit()" class="btn btn-danger text-uppercase min-w130 ripple-effect-dark">Submit<i class="fa fa-spin fa-spinner" *ngIf='loading'></i></button>
                  </div>
              </form>
          </div>
          <div class="login-footer">
              <a routerLink="/login" class="ripple-effect text-uppercase"> <i class="fa fa-long-arrow-left"></i> &nbsp; LOGIN</a>
          </div>
      </div>
  </main>
  </div>