// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false
};

let base_url = "";
base_url = "https://quit-vaping-api.codiantdev.com/api/admin"; // Codiant Production
 //base_url = "https://baitok-api-dev.codiantdev.com/api"; // development
//  base_url = "http://localhost:5003/api";

class Endpoints {
  AUTH_BASE = base_url;
  DASHBOARD_BASE = base_url + "/dashboard";
  REFERAL_SYSTEM_BASE = base_url + "/admin";
  MISCELLANEOUS_BASE = base_url + "";

  DASHBOARD_ENDPOINTS = {
    GET_DASHBOARD_DETAIL: this.joinPaths(base_url, "dashboard"),
  };

  AUTH_ENDPOINTS = {
    LOG_IN: this.joinPaths(this.AUTH_BASE, "login"),
    FORGET_PASSWORD: this.joinPaths(this.AUTH_BASE, "forgot-password"),
    RESET_PASSWORD: this.joinPaths(this.AUTH_BASE, "reset-password"),
    CHANGE_PASSWORD: this.joinPaths(this.AUTH_BASE, "change-password"),
    LOGOUT: this.joinPaths(this.AUTH_BASE, "logout")
  };

  USER_ENDPOINTS = {
    GET_USER_LIST: base_url + "/users",
    GET_ADMIN_DETAIL: base_url + "/admin-detail",
    UPDATE_DETAIL: base_url + "/update-detail",
    GET_USER_DETAIL: id => this.joinPaths(base_url + "/user", id),
    USER_STATUS_UPDATE_URL: id => this.joinPaths(base_url + "/user","change-status",id),
  };

  REPORTED_USER_ENDPOINTS = {
    GET_USER_LIST: base_url + "/reports",
    USER_REPORTED_STATUS_UPDATE_URL: id => this.joinPaths(base_url + "/update-reported-status",id),
    DELETE_REPORTED: id => this.joinPaths(base_url + "/delete-reported",id),
  };

  ARTICLE_ENDPOINTS = {
    GET_ARTICLE_LIST: base_url + "/articles",
    GET_ARTICLE_DETAIL: id => this.joinPaths(base_url + "/article", id),
    DELETE_ARTICLE: id => this.joinPaths(base_url + "/delete-article",id),
    ADD_ARTICLE:base_url + "/add-article",
    UPDATE_ARTICLE: id => this.joinPaths(base_url + "/update-article/" + id),
  };

  HEALTH_ENDPOINTS = {
    GET_HEALTH_LIST: base_url + "/health-list",
    GET_HEALTH_DETAIL: id => this.joinPaths(base_url + "/health", id),
    DELETE_HEALTH: id => this.joinPaths(base_url + "/delete-health",id),
    ADD_HEALTH: base_url + "/add-health",
    UPDATE_HEALTH: id => this.joinPaths(base_url + "/update-health/" + id),
  };
  
  TIPS_ENDPOINTS = {
    GET_TIPS_LIST: base_url + "/tips-list",
    GET_TIPS_DETAIL: id => this.joinPaths(base_url + "/tips", id),
    DELETE_TIPS: id => this.joinPaths(base_url + "/delete-tips",id),
    ADD_TIPS: base_url + "/add-tips",
    UPDATE_TIPS: id => this.joinPaths(base_url + "/update-tips/" + id),
  };

  TAGS_ENDPOINTS = {
    GET_TAGS_LIST: base_url + "/tags-list",
    GET_TAGS_DETAIL: id => this.joinPaths(base_url + "/tags", id),
    DELETE_TAGS: id => this.joinPaths(base_url + "/delete-tags",id),
    ADD_TAGS: base_url + "/add-tags",
    UPDATE_TAGS: id => this.joinPaths(base_url + "/update-tags/" + id),
  };

  PRODUCT_BANNER_ENDPOINTS = {
    GET_PRODUCT_BANNER_LIST: base_url + "/product-banner",
    GET_PRODUCT_BANNER_DETAIL: id => this.joinPaths(base_url + "/product-banner", id),
    DELETE_PRODUCT_BANNER: id => this.joinPaths(base_url + "/delete-product-banner",id),
    ADD_PRODUCT_BANNER:base_url + "/add-product-banner",
    UPDATE_PRODUCT_BANNER: id => this.joinPaths(base_url + "/update-product-banner/" + id),
  };
  
  PRODUCT_ENDPOINTS = {
    GET_PRODUCT_LIST: base_url + "/products",
    GET_BANNER_LIST: base_url + "/get-banner-list",
    GET_PRODUCT_DETAIL: id => this.joinPaths(base_url + "/product", id),
    DELETE_PRODUCT: id => this.joinPaths(base_url + "/delete-product",id),
    DELETE_PRODUCT_IMAGE: id => this.joinPaths(base_url + "/delete-product-image",id),
    ADD_PRODUCT:base_url + "/add-product",
    UPDATE_PRODUCT: id => this.joinPaths(base_url + "/update-product/" + id),
  };

  QUESTION_ENDPOINTS = {
    GET_QUESTION_LIST: type => this.joinPaths(base_url + "/questions", type),
    UPDATE_QUESTION: id => this.joinPaths(base_url + "/update-question", id),
  };

  CONTACT_ENDPOINTS = {
    GET_CONTACT_US_LIST: base_url + "/contacts",
    DELETE_CONTACT: id => this.joinPaths(base_url + "/delete-contact",id),
  };

  SETTING_ENDPOINTS = {
    GET_SETTING_LIST: base_url + "/setting",
    UPDATE_SETTING: type => this.joinPaths(base_url + "/update-setting", type),
    UPDATE_SETTING_STATUS: base_url + "/setting/change-status"
  };

  
  FAQ_ENDPOINTS = {
    GET_FAQ_LIST: base_url + "/faq",
    FAQ_STATUS_UPDATE_URL: id => this.joinPaths(base_url + "/faq-update-status",id),
    ADD_FAQ:base_url + "/add-faq",
    GET_FAQ_DETAIL: id => this.joinPaths(base_url + "/faq-detail", id),
    UPDATE_FAQ: id => this.joinPaths(base_url + "/update-faq", id),
  };

  CMS_ENDPOINTS = {
    GET_CMS_LIST: base_url + "/cms",
    GET_CMS_DETAIL: id => this.joinPaths(base_url + "/cms-detail", id),
    UPDATE_CMS: id => this.joinPaths(base_url + "/update-cms", id),
  };


  private joinPaths(...params) {
    const newUrl = params.join("/");
    return newUrl;
  }

  private joinPathsWithQueryParams(...params) {
    const newUrl = params.join("?");
    return newUrl;
  }
}

export const API = new Endpoints();
