import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import { DatePipe } from "@angular/common";
import * as moment from "moment";

// dateString: mm-dd-yyyy

@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
  parse(dateString): NgbDateStruct {
    const dateArray = dateString.split("-");
    return {
      month: dateArray[0],
      day: dateArray[1],
      year: dateArray[2]
    };
  }

  format(date: NgbDateStruct) {
    if (date) {
      return moment(new Date(`${date.year}/${date.month}/${date.day}`)).format("DD MMM,YYYY");
      // return `${date.month}-${date.day}-${date.year}`;
    }

    return "";
  }
}

export class MyDate {
  newDate: Date;

  constructor(date?: string) {
    if (date) {
      this.newDate = new Date(date);
    } else {
      this.newDate = new Date();
    }
  }

  getFullYear(): number {
    return this.newDate.getUTCFullYear();
  }

  getMonth(): number {
    return this.newDate.getUTCMonth();
  }

  getDate(): number {
    return this.newDate.getUTCDate();
  }
}

@Pipe({ name: "mydate" })
export class MyDatePipe implements PipeTransform {
  oldDatePipe: DatePipe;

  constructor() {
    this.oldDatePipe = new DatePipe("en-US");
  }

  transform(value: string, args: string): any {
    try {
      // debugger;
      // const trimmedTimeZoneVal1 = new Date(value);
      // const trimmedTimeZoneVal2 = new Date(value).toDateString();
      // value = "2019-04-01";

      const trimmedTimeZoneVal = value.split("T");
      if ((trimmedTimeZoneVal.length === 2)) {
        return moment(new Date(trimmedTimeZoneVal[0])).format("DD MMM,YYYY");
      } else {
        return moment(new Date(trimmedTimeZoneVal[0])).format("DD MMM,YYYY");
      }
      // return this.oldDatePipe.transform(value, args);
    } catch (ex) {
      // do nothing
    }

    return null;
  }
}

@Pipe({ name: "mydatetime" })
export class MyDateTimePipe implements PipeTransform {
  oldDatePipe: DatePipe;

  constructor() {
    this.oldDatePipe = new DatePipe("en-US");
  }

  transform(value: string, args: string): any {
    try {
      // const trimmedTimeZoneVal = value.split("T")[1];
      // return trimmedTimeZoneVal.split(".")[0];
      // const appliedDateTime = this.oldDatePipe.transform(value, args);
      // return appliedDateTime;

       return moment(new Date(value)).format("DD MMM,YYYY, h:mm A");

      // const trimmedTimeZoneVal = value.split("T");
      // if ((trimmedTimeZoneVal.length === 2)) {
      //   return moment(new Date(trimmedTimeZoneVal[0])).format("DD MMM,YYYY, h:mm A");
      // } else {
      //   return moment(new Date(trimmedTimeZoneVal[0])).format("DD MMM,YYYY, h:mm A");
      // }

    } catch (ex) {
      //   return "00:00";
    }

    return null;
  }
}

// export function timeZoneInfoTrimmer(date: string) {
//     let newDate: any = date.split('T');
//     newDate = newDate[0];
//     return newDate;
// }
