import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions, Response } from "@angular/http";
import "rxjs/Rx";
import { Observable } from "rxjs/Rx";
import { TokenManager } from "@app/shared/services/token-manager.service";
import { ApiHandler } from "@app/shared/services/api-handler.service";
import { API } from "environments/environment";

@Injectable()
export class AuthService {
  redirectUrl: string;

  constructor(
    private apiHandler: ApiHandler,
    private manageToken: TokenManager
  ) {}

  isLoggedIn() {
    // TODO: need to add logic to invalidate token
    if (this.manageToken.fetchToken()) {
      return true;
    }
  }

  loginUser(userName: string, password: string): Observable<any> {
    // Frame JSON body
    const loginReqBody = {
      email: userName,
      password: password,
      device_type: "web",
      device_id: "",
      environment: "dev"
    };

    return this.apiHandler
      .apiPost(API.AUTH_ENDPOINTS.LOG_IN, loginReqBody)
      .map((response: any) => {
        const data = response.data;
        const token = data.access_token;
        if (token) {
          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              user_type: data.role,
              // profile_image: data.user_profile.profile_image,
              token: token,
              name: data.first_name,
              id: data.id
              // photo: data.photo
            })
          );
        }
        return response;
      });
  }

  forgetPassword(formData) {
    return this.apiHandler.apiPost(
      API.AUTH_ENDPOINTS.FORGET_PASSWORD,
      formData
    );
  }

  resetPassword(formData) {
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.RESET_PASSWORD, formData);
  }

  changePassword(formData: any) {
    return this.apiHandler.apiPost(
      API.AUTH_ENDPOINTS.CHANGE_PASSWORD,
      formData
    );
  }

  logout() {
    const logoutReqBody = {
      access_token : this.manageToken.fetchToken()
    };
    return this.apiHandler.apiPost(API.AUTH_ENDPOINTS.LOGOUT, logoutReqBody);
  }
}
