import { Injectable } from "@angular/core";

@Injectable()
export class TokenManager {
  router: any;
  constructor() {}

  fetchToken() {
    const userData = localStorage.getItem("currentUser");
    let token = "";
    if (userData) {
      token = JSON.parse(userData).token;
    }
    return token;
  }

  deleteToken() {
    localStorage.removeItem("currentUser");
  }

  fetchData() {
    const userData = localStorage.getItem("currentUser");
    let name: any;
    if (userData) {
      name = JSON.parse(userData).name;
      // data['photo'] = JSON.parse(userData).photo;
    }
    return name;
  }

  fetchDataByKey(key: any) {
    const userData = localStorage.getItem(key);
    if (!userData) {
        return null;
    }
    try {
        return JSON.parse(userData);
    } catch (err) {
        //this.deleteData();
        this.router.navigate(["/"]);
    }
}

  storeDataByKey(key: any, value: any) {
    if (value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
  }

  deleteDataByKey(key) {
    localStorage.removeItem(key);
  }
}
